<template>
  <div class="topology-view">
    <organization-topology v-if="!systemTopologyVisible&&menuType" :zoneData="zoneData" :class="!menuVisible ? 'resizeTop' : ''" @clickZone="clickZone" @changeMenuType="changeMenuType"></organization-topology>
    <system-topology v-if="topologyVisible&&project_id" :project_id="project_id" :changeSize="changeSize"></system-topology>
    <!-- 底部菜单栏-->
    <zone-menu :class="!menuVisible ? 'change-visible' : ''" v-if="menuType" @clickZone="clickZone" @changeMenuType="changeMenuType" @zoneList="(list)=>zoneData=list"></zone-menu>
    <system-menu :class="!menuVisible ? 'change-visible' : ''" v-else :zone="zone" @changeMenuType="changeMenuType" @changeTopology="changeTopology(arguments)"></system-menu>
    <div class="change-box" ref="changeBox">
      <svg
        class="icon cursor-p"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="60"
        @click="changeMenu"
      >
        <path d="M512 870c-34.1 0-67-12.3-92.6-34.7L16.6 486.7c-20-17.3-22.2-47.7-4.9-67.7 17.3-20 47.7-22.2 67.7-4.9l403.1 348.8c16.8 14.7 42.2 14.7 59 0l0.2-0.2L944.6 414c20-17.3 50.4-15.2 67.7 4.9 17.3 20 15.2 50.4-4.9 67.7L604.6 835.3C579 857.7 546.1 870 512 870z" fill="#027ead"></path>
        <path d="M512 621.6c-34.1 0-67-12.3-92.6-34.7L16.6 238.3c-20-17.3-22.2-47.7-4.9-67.7 17.3-20 47.7-22.2 67.7-4.9l403.1 348.8c16.8 14.7 42.2 14.7 59 0l0.2-0.2 402.9-348.7c20-17.3 50.4-15.2 67.7 4.9 17.3 20 15.2 50.4-4.9 67.7L604.6 586.8c-25.6 22.5-58.5 34.8-92.6 34.8z" fill="#027ead"></path>
      </svg>
    </div>
  </div>
</template>

<script>
import OrganizationTopology from './modules/OrganizationTopology'
import SystemTopology from './modules/SystemTopology'
import ZoneMenu from './modules/ZoneMenu.vue'
import SystemMenu from './modules/SystemMenu.vue'

export default {
  name: 'TopologyView',
  components: {
    OrganizationTopology,
    SystemTopology,
    ZoneMenu,
    SystemMenu
  },
  data () {
    return {
      menuVisible: false,
      menuType: true,
      systemTopologyVisible: false,
      changeSize: 0,
      zone: undefined,
      topologyVisible: false,
      project_id: '',
      zoneData: []
    }
  },
  methods: {
    changeMenu () {
      this.menuVisible = !this.menuVisible
      if (this.menuVisible) {
        if (this.systemTopologyVisible) {
          // 当前展示的是拓扑
          this.changeSize = 0
        }
        this.$refs.changeBox.style.transform = 'rotate(0deg)'
      } else {
        if (this.systemTopologyVisible) {
          // 当前展示的是拓扑
          this.changeSize = 1
        }
        this.$refs.changeBox.style.transform = 'rotate(180deg)'
      }
    },
    changeMenuType (v) {
      this.menuType = v
      if (v) {
        this.menuVisible = false
        this.$refs.changeBox.style.transform = 'rotate(180deg)'
      }
    },
    clickZone (list) {
      this.zone = list
      this.menuVisible = true
      this.$refs.changeBox.style.transform = 'rotate(0deg)'
    },
    changeTopology (list) {
      this.topologyVisible = list[0]
      this.project_id = list[1].id
    }
    // fetchTopologyList () {
    //   getTopologyList({ project_id: this.project_id }).then(res => {
    //     if (res.data.data.length) this.topologyId = res.data.data[0].id
    //   })
    // }
  },
  watch: {
    // project_id: function (v) {
    //   if (v) { this.fetchTopologyList() } else this.topologyId = ''
    // }
  }
}
</script>

<style lang="less">
.topology-view {
  position: absolute;
  height: 1010px;
  width: 940px;
  box-sizing: border-box;
  overflow: hidden;

  .change-visible {
    transform: translateY(500px);
    opacity: 0;
  }

  .change-box {
    position: absolute;
    bottom: -8px;
    width: 100%;
    transform: rotate(180deg);

    svg {
      display: block;
      margin: auto;
      animation: slide .8s infinite alternate;
    }
    @keyframes slide {
      0% {
        transform: translateY(0px);
        opacity: .3;
      }
      100% {
        transform: translateY(4px);
        opacity: 1;
      }
    }
  }

  .resizeTop{
    top:120px;
  }
}
</style>
