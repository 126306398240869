<template>
  <div class="alert-trend">
    <dv-border-box-13 backgroundColor="#002f9610">
      <p class="module-title not-select">7日告警趋势</p>
      <div id="trend-chart"
           style="width:450px;height:260px"></div>
    </dv-border-box-13>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
import { getAlertEventTrend } from '@/api/alert-event'

var trendChart = null

export default {
  name: 'AlertTrend',
  data () {
    return {
      data: [],
      timer: null,
      date: []
    }
  },
  mounted () {
    this.initTrendChart()
    this.refresh()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    trendChart.clear()
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60)
    },
    initTrendChart () {
      if (trendChart !== null) trendChart.dispose()
      trendChart = echarts.init(document.getElementById('trend-chart'))

      const severity = ['disaster', 'high', 'average', 'warning']
      const severity1 = ['严重', '紧急', '普通', '预警']
      const values = [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0]]
      let now = moment().format('YYYY-MM-DD')
      for (let i = 0; i < 7; i++) {
        this.date.push(now)
        now = moment(now).subtract(1, 'day').format('YYYY-MM-DD')
      }
      this.date.reverse()
      this.date.forEach((ele, i) => {
        this.date[i] = this.formatDate(ele)
      })

      var color = [
        'rgba(243,95,113',
        'rgba(246,189,22',
        'rgba(23,255,243',
        'rgba(51,146,255'
      ]
      var lineY = []
      for (var i = 0; i < severity.length; i++) {
        var data = {
          name: severity1[i],
          type: 'line',
          color: color[i] + ')',
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: color[i] + ', 0.3)'
                },
                {
                  offset: 0.8,
                  color: color[i] + ', 0)'
                }
              ],
              false
            ),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
          },
          symbol: 'circle',
          symbolSize: 5,
          data: values[i]
        }
        lineY.push(data)
      }

      var option = {
        animationDuration: 600,
        animationDurationUpdate: 600,
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: severity1,
          textStyle: {
            fontSize: 12,
            color: 'rgb(0,253,255,0.6)'
          },
          right: '95',
          top: '10'
        },
        grid: {
          top: '45',
          left: '25',
          right: '25',
          bottom: '60',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.date,
          splitLine: {
            lineStyle: {
              color: 'rgb(23,255,243,0.3)'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(0,253,255,0.6)'
            }
          },
          axisLabel: {
            margin: 10,
            color: 'rgb(0,253,255,0.6)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            color: 'rgb(0,253,255,0.6)'
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(0,253,255,0.6)'
            }
          }
        },
        series: lineY
      }
      trendChart.setOption(option)
    },
    fetch () {
      const params = {
        trend_by: 'severity',
        date_from: moment().subtract(6, 'day').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD')
      }
      getAlertEventTrend(params).then(res => {
        this.data = res.data.data
        const series = []
        const severity = ['disaster', 'high', 'average', 'warning']
        const values = [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0]]
        if (this.data.length) {
          console.log(this.date, this.data)
          for (let i = 0; i < this.date.length; i++) {
            const item = this.date[i]
            console.log(this.data)
            const arr = this.data.filter(ele => this.formatDate(ele.date) === item)
            severity.forEach((e, index) => {
              const count = arr.find(e1 => e1.name === e)
              if (count !== undefined) {
                values[index][i] = count.value
              }
            })
          }
        }
        for (let i = 0; i < severity.length; i++) {
          series.push({ data: values[i] })
        }
        trendChart.setOption({ series })
      })
    },
    formatDate (date) {
      const arr = date.split('-')
      return arr[1] + '.' + arr[2]
    }
  }
}
</script>

<style lang="less">
.alert-trend {
  position: absolute;
  height: 270px;
  width: 450px;
  box-sizing: border-box;
}
</style>
