<template>
  <div class="asset-card">
    <a-row>
      <a-col class="title"
             :span="5"
             style="textAlign:center;height:78px">
        <svg-icon :icon-name='iconName'
                  class="source-icons not-select"></svg-icon>
        <p style="margin-top:-7px;font-size:14px;color:rgba(255,255,255,.9)" class="not-select">{{title}}</p>
      </a-col>
      <a-col :span="19"
             style="height:78px;padding-left:5px">
        <a-row>
          <a-col class="mt10"
                 :span="6" v-for="item in typeData" :key="item.name">
            <a-row style="display:flex;margin-bottom:7px">
              <div style="width:3px;height:8px;backgroundColor:#f9db3c;marginLeft:13px"></div>
              <h3 style="color:#fff;font-size:12px;font-weight:400;margin-left:5px;line-height:8px;" class="not-select cursor-p" @click="goAssetDetail(item.name)">{{sourceType!=='host'? item.name:$t(`host_type.${item.name}`)}}</h3>
            </a-row>
            <a-row>
              <p class="fc3 box not-select cursor-p" @click="goAssetDetail(item.name)">{{item.value}}</p>
            </a-row>
            <a-row>
              <div class="bottom"></div>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import svgIcon from '@/components/SvgIcon'
import { getSourceCountFunc } from '@/utils'

export default {
  name: 'AssetCard',
  components: {
    svgIcon
  },
  props: {
    sourceType: { type: String, required: true },
    iconName: { type: String, required: true }
  },
  data () {
    return {
      typeData: [],
      timer: null
    }
  },
  computed: {
    count_by () {
      switch (this.sourceType) {
        case 'host':
          return 'host_type'
        case 'system':
          return 'os_type'
        default:
          return 'type'
      }
    },
    countFunc () {
      return getSourceCountFunc(this.sourceType)
    },
    title () {
      switch (this.sourceType) {
        case 'host':
          return '主机'
        case 'middleware':
          return '中间件'
        case 'database':
          return '数据库'
        default:
          return '系统'
      }
    }
  },
  created () {
    this.refresh()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60 * 30)
    },
    fetch () {
      const params = { count_by: this.count_by }
      this.countFunc(params).then(res => {
        const data = res.data
        if (this.sourceType === 'host') {
          this.typeData = [{ name: 'physical', value: 0 }, { name: 'virtual', value: 0 }]
          data.data.forEach(ele => {
            if (ele.name === 'virtual') this.typeData[1].value = ele.value
            if (ele.name === 'physical') this.typeData[0].value = ele.value
          })
        } else {
          // 过滤不必要的数据
          switch (this.sourceType) {
            case 'database':
              this.typeData = data.data.filter(ele => ele.name === 'mysql' || ele.name === 'oracle' || ele.name === 'postgresql')
              break
            case 'middleware':
              this.typeData = data.data.filter(ele => ele.name === 'nginx' || ele.name === 'tomcat' || ele.name === 'apache')
              break
            default:
              this.typeData = data.data.splice(0, 4)
              break
          }
        }
      })
    },
    goAssetDetail (name) {
      let params = {}
      switch (this.sourceType) {
        case 'host':
          params = { hostType: name }
          this.$router.push({ name: 'HostList', params })
          break
        case 'middleware':
          params = { middlewareType: name }
          this.$router.push({ name: 'MiddlewareList', params })
          break
        case 'system':
          params = { osType: name }
          this.$router.push({ name: 'HostList', params })
          break
        default:
          params = { databaseType: name }
          this.$router.push({ name: 'DatabaseList', params })
          break
      }
    }
  }
}
</script>

<style lang="less">
.asset-card {
  width: 440px;
  height: 78px;
  box-shadow: 0px 0px 20px #0c2c6c7b, inset 0px 0px 20px #1e50ae8e;
  position: relative;

  .title {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      height: 60px;
      width: 1px;
      border-left: 1px solid #3fe4ff;
      right: 0;
      top: 10px;
    }
  }
  .mt10 {
    margin-top: 15px;
  }

  .box {
    margin-right: 22px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 600;
    text-align: right;
    position: relative;
  }

  .bottom {
    position: relative;
    margin: -15px 10px 0;
    border-bottom: 1.5px solid #3fe5ff76;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -2.5px;
      right: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #3fe4ff;
    }
  }

  .source-icons {
    height: 40px;
    width: 40px;
    color: rgb(0, 136, 255);
    margin-top: 7px;
    margin-bottom: 5px;
  }
}
</style>
