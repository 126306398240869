<template>
  <div class="system-topology">
    <div style="text-align: right;">
      <a @click="showModal" style="margin-top:10px;">
        <a-icon type="fullscreen" style="fontSize:20px" />
      </a>
    </div>
    <div id="topology"></div>
    <big-topology ref="bigTopology"></big-topology>
  </div>
</template>

<script>
import { Graph, Shape } from '@antv/x6'
import { getProjectTopology } from '@/api/project'
import { getSourceRouterName } from '@/utils'
import BigTopology from './BigTopology.vue'
import { Group } from '@/utils/topology'

export default {
  name: 'SystemTopology',
  props: {
    project_id: { type: String, required: true },
    changeSize: { type: Number, required: true }
  },
  components: { BigTopology },
  data () {
    return {
      graph: null,
      topologyData: undefined
    }
  },
  mounted () {
    this.initGraph()
    this.fetch()
  },
  methods: {
    initGraph () {
      const container = document.getElementById('topology')
      this.graph = new Graph({
        container,
        grid: false,
        mousewheel: {
          enabled: true,
          modifiers: 'ctrl',
          minScale: 0.5,
          maxScale: 4
        },
        embedding: {
          enabled: false
        },
        interacting: {
          nodeMovable: false
        },
        connecting: {
          router: 'manhattan',
          connector: 'rounded',
          createEdge () {
            return new Shape.Edge({
              attrs: {
                line: {
                  stroke: '#1890ff',
                  strokeDasharray: 5,
                  targetMarker: 'classic',
                  style: { animation: 'topology-edge 60s infinite linear' }
                }
              }
            })
          }
        },
        scroller: false,
        panning: true
      })
      this.graph.on('node:click', ({ node }) => {
        if (!node.data.parent) {
          this.$router.push({
            name: getSourceRouterName(node.data.sourceType),
            params: { id: node.data.sourceId }
          })
          const elements = document.getElementsByClassName('ant-tooltip')
          for (const element of elements) {
            if (element.style.display !== 'none') {
              element.style.display = 'none'
            }
          }
        }
      })
      this.graph.on('node:collapse', ({ node, e }) => {
        e.stopPropagation()
        node.toggleCollapse()
        const collapsed = node.isCollapsed()
        const collapse = (parent) => {
          const cells = parent.getChildren()
          if (cells) {
            cells.forEach((cell) => {
              if (collapsed) {
                cell.hide()
              } else {
                cell.show()
              }
              if (cell instanceof Group) {
                if (!cell.isCollapsed()) {
                  collapse(cell)
                }
              }
            })
          }
        }
        collapse(node)
      })
    },
    fetch () {
      getProjectTopology(this.project_id).then((res) => {
        this.topologyData = res.data || {}
        this.graph.fromJSON(this.topologyData)
        this.graph.zoomToFit({ maxScale: 1.5 })
        this.graph.centerContent({ padding: { left: -60 } })
      })
    },
    showModal () {
      this.$refs.bigTopology.show(this.topologyData)
    }
  },
  watch: {
    project_id: function (v) {
      if (v) this.fetch()
      else this.graph.fromJSON({})
    },
    changeSize: function (v) {
      const { left, top } = { ...this.graph.getScrollbarPosition() }
      if (v) {
        this.graph.resize(940, 970)
        this.graph.zoomToFit()
        this.graph.zoom(-0.06)
        this.graph.centerContent({ padding: { left: -60 } })
      } else {
        this.graph.resize(940, 695)
        this.graph.zoomToFit()
        this.graph.zoom(-0.06)
        this.graph.centerContent({ padding: { left: -60 } })
      }
      this.graph.setScrollbarPosition(left, top)
    }
  }
}
</script>

<style lang="less">
.system-topology {
  width: 940px;
  height: 695px;

  #topology {
    width: 100%;
    height: 100%;
  }

  @keyframes topology-edge {
    to {
      stroke-dashoffset: -1000;
    }
  }
}
</style>
