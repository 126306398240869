<template>
  <div class="alert-now cursor-default">
    <dv-border-box-13 backgroundColor="#002f9610">
      <p class="module-title not-select">当前告警</p>

      <div style="padding:0 10px 0 20px">
        <a-row v-for="(i, index) in data" :key="index" style="float:left;width:210px;margin-top:35px;">
          <a-col :class="'col' + (index + 1)" style="display:flex;padding-left:60px;marginBottom:10px">
            <div style="width:6px;height:12px;backgroundColor:#ff6461;transform:skew(-25deg)"></div>
            <div style="width:6px;height:12px;backgroundColor:#ff6461;transform:skew(-25deg);margin-left:6px"></div>
            <h3 style="color:#fff;font-size:14px;font-weight:400;margin-left:20px;line-height:12px" class="not-select"
              @click="goAlertNow(index)" :class="index === 0 ? 'cursor-p' : 'cursor-d'">{{ index === 0 ? '告警中' : '处理率' }}</h3>
          </a-col>
          <a-col>
            <p class="fc3 box not-select" :class="['box' + (index + 1), { 'cursor-p': index === 0 }, { 'cursor-d': index === 1 }]"
              @click="goAlertNow(index)">
              <count-to :start-val="0" separator="" :end-val="i" :duration="1500" :decimals="index === 1 && i !== 100 ? 2 : 0">
              </count-to>
            </p>
          </a-col>
          <a-col>
            <div class="bottom" :class="'bottom' + (index + 1)"></div>
          </a-col>
        </a-row>
      </div>
    </dv-border-box-13>
  </div>
</template>

<script>
import { getAlertEventList } from '@/api/alert-event'
import CountTo from 'vue-count-to'
// [{'box'+(index+1)},{index===0?'cursor-p':'cursor-d'}]

export default {
  name: 'AlertNow',
  components: { CountTo },
  data () {
    return {
      data: [0, 0],
      timer: null,
      params: { recovered: 'false' },
      alertTotal: 0,
      alertNow: 0
    }
  },
  created () {
    this.refresh()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    refresh () {
      this.fetch()
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60)
    },
    fetch () {
      let total = 0
      let now = 0
      let rate = 0
      getAlertEventList().then(res => {
        total = res.data.total
      }).finally(() => {
        getAlertEventList(this.params).then(res => {
          now = res.data.total
        }).finally(() => {
          rate = parseInt(((total - now) / total * 10000)) / 100
          this.data = [now, rate]
        })
      })
    },
    goAlertNow () {
      const params = { recovered: 'false' }
      this.$router.push({ name: 'HistoricalAlert', params })
    }
  }
}
</script>

<style lang="less">
.alert-now {
  position: absolute;
  height: 220px;
  width: 450px;
  box-sizing: border-box;

  .col2 {
    padding-left: 80px !important;

    div {
      background-color: #00ff77 !important;
    }
  }

  .box1::after {
    content: "条";
  }

  .box2::after {
    content: "%";
  }

  .box {
    margin-right: 50px;
    font-size: 36px;
    font-family: Verdana;
    font-weight: 600;
    text-align: right;
    position: relative;

    &::after {
      display: inline-block;
      position: absolute;
      right: -25px;
      top: 0;
      font-size: 12px;
      font-family: "PingFang", sans-serif;
    }
  }

  .bottom {
    position: relative;
    margin: -25px 30px 0 20px;
    border-bottom: 1.5px solid #3fe5ff76;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -2.5px;
      right: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #3fe4ff;
    }
  }

  .bottom2 {
    margin: -25px 30px 0 40px;
  }
}
</style>
