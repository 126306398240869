<template>
  <div class="asset-statistics">
    <dv-border-box-13 backgroundColor="#002f9610">
      <p class="module-title not-select">资产统计</p>
      <div class="assets-box">
        <asset-card v-for="item in sourceType"
                    :key="item" :sourceType='item' :iconName='item'></asset-card>
      </div>
    </dv-border-box-13>
  </div>
</template>

<script>
import AssetCard from './modules/AssetCard.vue'

export default {
  name: 'AssetStatistics',
  components: {
    AssetCard
  },
  data () {
    return {
      type: ['主机', '中间件', '数据库', '系统'],
      sourceType: ['host', 'system', 'middleware', 'database'],
      typeNumber: [
        {
          物理机: 20,
          虚拟机: 24
        },
        {
          redis: 25,
          tomcat: 34,
          nginx: 78
        },
        {
          mysql: 20,
          postgre: 24,
          oracle: 54
        },
        {
          windows: 20,
          linux: 24
        }
      ],
      sourceData: []
    }
  }
}
</script>

<style lang="less">
.asset-statistics {
  position: absolute;
  height: 420px;
  width: 450px;
  box-sizing: border-box;

  .assets-box {
    margin-left: 20px;
    margin-top: 10px;
    width: 410px;
    height: 340px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
  }
}
</style>
