<template>
  <a-modal wrapClassName="topology-modal" :maskStyle="{ backgroundColor: 'rgba(0,0,0,.75)' }" v-model="visible"
    @ok="handleOk" :width="1500" :height="600" :footer="null" :destroyOnClose="true">
    <div id="bigTopology"></div>
  </a-modal>
</template>

<script>
import { Graph } from '@antv/x6'
import { Group } from '@/utils/topology'

export default {
  name: 'BigTopology',
  data () {
    return {
      visible: false,
      graph: null,
      detail: {
        project: {}
      },
      container: null
    }
  },
  methods: {
    initGraph (data) {
      this.container = document.getElementById('bigTopology')
      if (this.container === null) {
        const div = setInterval(() => {
          if (document.getElementById('bigTopology') !== null) {
            this.container = document.getElementById('bigTopology')
            window.clearInterval(div)
            this.drawGraph(data)
          }
        }, 1000)
      } else {
        this.drawGraph(data)
      }
    },
    drawGraph (data) {
      this.graph = new Graph({
        container: this.container,
        grid: false,
        mousewheel: {
          enabled: true,
          modifiers: 'ctrl',
          minScale: 0.5,
          maxScale: 2
        },
        embedding: {
          enabled: false
        },
        interacting: {
          nodeMovable: false
        },
        connecting: {
          router: 'manhattan',
          connector: 'rounded'
        },
        scroller: false,
        panning: true

      })
      this.graph.on('node:collapse', ({ node, e }) => {
        e.stopPropagation()
        node.toggleCollapse()
        const collapsed = node.isCollapsed()
        const collapse = (parent) => {
          const cells = parent.getChildren()
          if (cells) {
            cells.forEach((cell) => {
              if (collapsed) {
                cell.hide()
              } else {
                cell.show()
              }
              if (cell instanceof Group) {
                if (!cell.isCollapsed()) {
                  collapse(cell)
                }
              }
            })
          }
        }
        collapse(node)
      })
      this.graph.fromJSON(data)
      this.graph.zoomToFit({ maxScale: 1.5 })
      this.graph.zoom(-0.1)
      this.graph.centerContent({ padding: { left: -60, top: -60 } })
    },
    show (data) {
      this.visible = true
      this.initGraph(data)
    },
    handleOk () {
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.topology-modal {

  .ant-modal-content {
    background-color: rgba(5, 25, 61, 0.5);

    .ant-modal-close {
      color: rgba(255, 255, 255, .8) !important;
    }

    .ant-modal-body {
      padding: 0;

      #bigTopology {
        height: 800px;
        width: 100%;
      }
    }
  }
}
</style>
