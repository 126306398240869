<template>
  <div class="usage-statistics">
    <dv-border-box-13 backgroundColor="#002f9610">
      <p class="module-title not-select">CPU 使用率</p>
      <div id="cpu-chart" style="width: 450px; height: 230px;"></div>
    </dv-border-box-13>
  </div>
</template>

<script>
import { getHostMonitorPerformance } from '@/api/host'
import { subTitle } from '@/utils'
import * as echarts from 'echarts'

var cpuChart = null

export default {
  name: 'CpuUsageStatistics',
  data () {
    return {
      data: [],
      option: {},
      maxData: [100, 100, 100, 100, 100],
      xName: [],
      timer: null
    }
  },
  mounted () {
    this.initChart()
    this.refresh()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    cpuChart.clear()
  },
  methods: {
    refresh () {
      this.timer = setInterval(() => {
        this.fetch()
      }, 1000 * 60 * 30)
    },
    initChart () {
      if (cpuChart !== null) cpuChart.dispose()
      cpuChart = echarts.init(document.getElementById('cpu-chart'))
      this.option = {
        grid: {
          top: 5,
          left: 20,
          right: 20,
          bottom: 0,
          containLabel: true
        },
        tooltip: {
          show: true,
          formatter: (params) => {
            return params.name
          }
        },
        xAxis: [{
          show: false,
          min: 0,
          max: 100
        }],
        yAxis: [
          {
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            axisLabel: {
              margin: 10,
              color: 'rgba(255,255,255,.9)',
              fontSize: '12',
              formatter: params => {
                return subTitle(params, 15)
              }
            },
            data: this.xName,
            triggerEvent: true
          },
          {
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            type: 'category',
            axisLabel: {
              margin: 20,
              color: '#6DE8FA',
              fontSize: '14',
              formatter: (params) => { return params + '%' }
            },
            data: this.data
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: 18,
            itemStyle: {
              color: (params) => {
                if (params.value >= 85) {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(255,100,121,.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,100,121,1)'
                    }
                  ])
                } else if (params.value >= 60) {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(0,107,255,0.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,107,255,1)'
                    }
                  ])
                } else {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: 'rgba(36,188,255,.1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(36,188,255,1)'
                    }
                  ])
                }
              }
            },
            data: this.data,
            z: 0
          },
          {
            type: 'pictorialBar',
            itemStyle: {
              color: '#011140'
            },
            symbolRepeat: 11,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [4, 20],
            data: this.data,
            z: 1
          },
          {
            type: 'bar',
            barGap: '-117%',
            data: this.maxData,
            barWidth: 24,
            itemStyle: {
              color: 'transparent',
              borderColor: '#0e4160',
              borderWidth: 1
            },
            z: 2
          }
        ]
      }
      cpuChart.setOption(this.option)
      this.fetch()
      cpuChart.on('click', params => {
        if (params.componentType === 'yAxis') { this.$router.push({ name: 'HostDetail', params: { id: this.data[params.tickIndex].id } }) }
      })
    },
    fetch () {
      this.xName = []
      getHostMonitorPerformance({ key: 'system.cpu.util', limit: 5, project_id: this.projectId }).then((res) => {
        this.data = res.data.data
        this.data.forEach(element => {
          this.xName.push(element.name)
        })
        cpuChart.setOption({
          yAxis: [
            { data: this.xName },
            { data: this.data }
          ],
          series: [{ data: this.data }, { data: this.data }]
        })
      }
      )
    }
  }
}
</script>

<style lang="less">
.usage-statistics {
  position: absolute;
  height: 270px;
  width: 450px;
  box-sizing: border-box;
}
</style>
