<template>
  <div class="alert-totals cursor-default">
    <dv-border-box-13 backgroundColor="#002f9610">
      <p class="module-title not-select">累计告警</p>
      <div id="severity-chart" style="height:140px;margin-left:5px;margin-bottom:10px;width:425px;"></div>
      <div id="source-chart" style="height:250px;width:425px;margin-left:5px; "></div>
    </dv-border-box-13>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import { getAlertEventList } from '@/api/alert-event'
import { sourceTypes } from '@/utils'

var severityChart = null
var sourceChart = null

export default {
  name: 'AlertTotals',
  data () {
    return {
      data: [
        [0.6, 0.55, 0.5],
        [0.6, 0.55, 0.5],
        [0.6, 0.55, 0.5],
        [0.6, 0.55, 0.5]
      ],
      color: ['#f35f71', '#f6bd16', '#17fff3', '#3392ff'],
      severity: ['disaster', 'high', 'average', 'warning'],
      severityName: ['紧急', '严重', '普通', '预警'],
      sourceTypes: sourceTypes(),
      sourceTypeData: [],
      timer: null
    }
  },
  mounted () {
    this.initSeverityChart()
    this.initSourceChart()
    this.refresh()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    severityChart.clear()
    sourceChart.clear()
  },
  methods: {
    refresh () {
      this.timer = setInterval(() => {
        this.fetchSeverity()
        this.fetchSourceType()
      }, 1000 * 60)
    },
    fetch (params) {
      return getAlertEventList(params)
    },
    initSeverityChart () {
      if (severityChart !== null) severityChart.dispose()
      severityChart = echarts.init(document.getElementById('severity-chart'))
      const graphic = []
      const series = []
      let left = 50
      let left1 = 60
      for (let i = 0; i < this.severity.length; i++) {
        const obj = {
          type: 'group',
          left,
          top: '85',
          children: [{
            type: 'text',
            z: 100,
            left: 'center',
            top: '25',
            style: {
              fill: 'rgba(255,255,255,1)',
              text: '(条)',
              fontSize: 10
            }
          }, {
            type: 'text',
            z: 100,
            left: 'center',
            top: '60',
            style: {
              fill: this.color[i],
              text: this.severityName[i],
              fontSize: 12
            }
          }]
        }
        left = left + 100
        graphic.push(obj)
        const obj1 = {
          type: 'liquidFill',
          center: [left1, '65'],
          color: [this.color[i]],
          radius: '55%',
          animationDuration: 600,
          animationDurationUpdate: 600,
          data: this.data[i],
          label: {
            fontSize: 20,
            formatter: () => { return 0 }
          },
          outline: {
            borderDistance: 3,
            itemStyle: {
              borderWidth: 5,
              borderColor: this.color[i]
            }
          },
          backgroundStyle: {
            color: 'rgba(0,0,0,0)'
          }
        }
        left1 = left1 + 100
        series.push(obj1)
      }
      severityChart.setOption({ graphic, series })
      this.fetchSeverity()
      severityChart.on('click', param => {
        if (param.componentType === 'series') {
          const params = { severity: this.severity[param.componentIndex] }
          this.$router.push({ name: 'HistoricalAlert', params })
        }
      })
    },
    initSourceChart () {
      if (sourceChart !== null) sourceChart.dispose()
      sourceChart = echarts.init(document.getElementById('source-chart'))
      const xLabel = []
      this.sourceTypes.forEach(ele => {
        xLabel.push(ele.title)
      })
      const option = {
        tooltip: {},
        animation: true,
        animationDuration: 600,
        animationDurationUpdate: 600,
        grid: {
          top: '20',
          bottom: '30',
          right: '20',
          left: '55'
        },
        xAxis: {
          data: xLabel,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#11417a'
            }
          },
          axisTick: 'none',
          axisLabel: {
            show: true,
            margin: 14,
            fontSize: 12,
            color: 'rgb(0,253,255,0.6)'
          },
          triggerEvent: true
        },
        yAxis: [
          {
            type: 'value',
            gridIndex: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#113763',
                width: 1
              }
            },
            axisTick: 'none',
            axisLine: 'none',
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 12,
              color: 'rgb(0,253,255,0.6)'
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(36,188,255,1)'
                },
                {
                  offset: 1,
                  color: '#034881'
                }
              ])
            },
            data: [],
            z: 10,
            zlevel: 0,
            label: {
              show: true,
              position: 'top',
              distance: 5,
              fontSize: 14,
              color: '#01fff4'
            }
          },
          {
            type: 'pictorialBar',
            itemStyle: {
              color: '#0F375F'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 6,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: 'start',
            symbolOffset: [0, -1],
            data: [],
            width: 25,
            z: 0,
            zlevel: 1
          }
        ]
      }
      sourceChart.setOption(option)
      this.fetchSourceType()
      sourceChart.on('click', param => {
        const params = { sourceType: this.sourceTypes[param.dataIndex].value }
        this.$router.push({ name: 'HistoricalAlert', params })
      })
    },
    async fetchSeverity () {
      const series = []
      for (let i = 0; i < this.severity.length; i++) {
        const params = { severity: this.severity[i] }
        await this.fetch(params).then(res => {
          series.push({
            label: {
              fontSize: 20,
              formatter: () => res.data.total
            }
          })
        })
      }
      severityChart.setOption({ series })
    },
    async fetchSourceType () {
      for (let i = 0; i < this.sourceTypes.length; i++) {
        const params = { source_type: this.sourceTypes[i].value }
        await this.fetch(params).then(res => {
          this.sourceTypeData.push(res.data.total)
        })
      }
      sourceChart.setOption({ series: [{ data: this.sourceTypeData }, { data: this.sourceTypeData }] })
    }
  }
}
</script>

  <style lang="less">
  .alert-totals {
    position: absolute;
    height: 470px;
    width: 450px;
    box-sizing: border-box;
  }
  </style>
