<template>
  <div class="zone-menu">
    <dv-border-box-13 backgroundColor="#002f9610">
      <p class="module-title not-select">组织</p>
      <div class="outer-box">
        <div class="btn-box">
          <div class="btn" v-for="item in zoneData" :key="item.name">
            <a-tooltip placement="bottom" :title="item.name">
              <div class="bg" >
                <p class="title cursor-p not-select" @click="changeMenu(item)">{{ item.name }}</p>
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </dv-border-box-13>
  </div>
</template>

<script>
import { getZoneList } from '@/api/zone'

export default {
  name: 'ZoneMenu',
  data () {
    return {
      zoneData: [],
      form: { page: 1, pageSize: 10 },
      total: 0
    }
  },
  mounted () {
    this.fetchZoneList()
  },
  methods: {
    fetchZoneList () {
      getZoneList(this.form).then(res => {
        this.total = res.data.total
        this.zoneData.push(...res.data.data)
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetchZoneList()
        }
        this.$emit('zoneList', this.zoneData)
      })
    },
    changeMenu (item) {
      this.zoneData = []
      this.form.page = 1
      this.$emit('clickZone', item)
      this.$emit('changeMenuType', false)
    }
  }
}
</script>

<style lang="less">
.zone-menu {
  width: 940px;
  height: 270px;
  position: absolute;
  bottom: 30px;
  transition: all 0.6s;
  opacity: 1;

  .module-title {
    background-repeat: round !important;
    height: 55px !important;
  }
  .outer-box {
    width: 930px;
    height: 195px;
    overflow: hidden;
    position: relative;

    .btn-box {
      width: 930px;
      height: 905px;
      position: absolute;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-left: 15px;

      .btn {
        position: relative;
        top: 20px;
        left: 20px;
        width: 200px;
        height: 90px;
        margin-right: 21px;
        margin-bottom: 30px;
        padding-left: 2px;
        background: linear-gradient(to left, #41cfff, #41cfff) left top no-repeat,
          linear-gradient(to bottom, #41cfff, #41cfff) left top no-repeat,
          linear-gradient(to left, #41cfff, #41cfff) right top no-repeat,
          linear-gradient(to bottom, #41cfff, #41cfff) right top no-repeat,
          linear-gradient(to left, #41cfff, #41cfff) left bottom no-repeat,
          linear-gradient(to bottom, #41cfff, #41cfff) left bottom no-repeat,
          linear-gradient(to left, #41cfff, #41cfff) right bottom no-repeat,
          linear-gradient(to left, #41cfff, #41cfff) right bottom no-repeat;
        background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
        background-color: rgba(0, 42, 78, 0.33);
        display: flex;
        align-items: center;

        .bg {
          width: 195px;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .title {
          min-width: 195px;
          max-width: 195px;
          text-align: center;
          margin: 0;
          line-height: 25px;
          padding: 0 15px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .active {
          background: linear-gradient(to right, #004bbcb6, #00409f4f);
        }
      }
    }
  }
}
</style>
