<template>
  <div class="organization-topology">
    <div class="icon-box">
      <svg-icon icon-name="cloud" class="cloud-icon"></svg-icon>
    </div>
    <div class="overflow-box">
      <div class="cloud-item-box not-select" v-if="zoneData.length">
        <div class="item cursor-p" v-for="(item) in zoneData" :key="item.id" @click="goProject(item)">
          <img src="../../../../../assets/images/cloud-item.png" class="item-img">
          <p class="not-select item-title ">{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import svgIcon from '@/components/SvgIcon'

export default {
  name: 'OrganizationTopology',
  components: {
    svgIcon
  },
  props: {
    zoneData: { type: Array }
  },
  methods: {
    goProject (v) {
      this.$emit('clickZone', v)
      this.$emit('changeMenuType', false)
    }
  }
}
</script>

<style lang="less">
.organization-topology {
  width: 940px;
  height: 695px;
  position: relative;
  // background-color: #fff;
  // top:160px;

  .icon-box {
    width: 940px;
    height: 695px;
    position: absolute;
    text-align: center;

    &::after {
      content: "政务云";
      position: absolute;
      top: 210px;
      left: 270px;
      width: 200px;
      display: inline-block;
      font-size: 24px;
      font-weight: 600;
      opacity: 0.45;
      color: #ffffff;
    }

    .cloud-icon {
      width: 820px;
    }
  }

  .overflow-box {
    overflow: hidden;
    height: 235px;
    .cloud-item-box {
      top: 310px;
      left: 240px;
      width: 430px;
      height: 235px;
      position: absolute;
      // background-color: rgba(2, 6, 40, 0.17);
      //  box-shadow: 0px 0px 30px #0c2c6c9e, inset 0px 0px 30px #1e50aea2;
      background: #00327c8e;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-top: 40px;
      align-items: center;
      overflow-y: scroll;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
      }

      &::after {
        content: "科创局大数据管理中心";
        position: absolute;
        display: inline-block;
        top: 8px;
        left: 10px;
        font-size: 16px;
        color: #ffffff;
        opacity: 0.75;
      }

      .item {
        width: 100px;
        height: 100px;
        // position: absolute;
        z-index: 999;
        overflow: hidden;

        // top: 70px;
        // margin-left:15px;
        .item-img {
          width: 50px;
          height: 50px;
          margin-left: 25px;
        }

        .item-title {
          font-size: 14px;
          color: rgba(255, 255, 255, .9);
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-align: center;
        }
      }
    }
  }
}
</style>
